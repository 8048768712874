















import { Component, PropSync, Vue } from 'vue-property-decorator';

@Component
export default class DeleteItemModal extends Vue {
  @PropSync('show', { type: Boolean, required: true })
   visible!: Boolean;

  close() {
    this.$emit('update:show', false);
  }

  valid() {
    this.$emit('valid');
    this.$emit('update:show', false);
  }
}
